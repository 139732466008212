<template>
	<div class="container text-left">
		<h1>User details</h1>

		<div class="alert alert-danger" v-if="error">
			{{ error }}
		</div>

		<form>
			<div class="form-group">
				<label>Name</label>
				<input
					ref="regName"
					type="text"
					class="form-control"
					v-model="regName"
				/>
			</div>
			<div class="form-group">
				<label>Email</label>
				<input type="email" class="form-control" v-model="regEmail" readonly />
			</div>
			<div class="form-group">
				<label>Language</label>
				<b-form-select v-model="language" :options="langs"></b-form-select>
			</div>

			<div class="form-row">
				<div class="form-group col-sm">
					<label>New password</label>
					<input
						type="password"
						class="form-control"
						v-model="password"
						@blur="validatePassword"
						:class="{ 'is-invalid': !passwordValid }"
						autocomplete="new-password"
					/>
					<div class="invalid-feedback" v-if="passwordNotEqual">
						Password must be minimum 8 chars with numbers, low and high characters and at least 1 digit
					</div>
				</div>
				<div class="form-group col-sm">
					<label>Repeat new password</label>
					<input
						type="password"
						class="form-control"
						v-model="password2"
						@blur="checkPasswordsEqual"
						:class="{ 'is-invalid': passwordNotEqual }"
						autocomplete="new-password"
					/>
					<div class="invalid-feedback" v-if="passwordNotEqual">
						Passwords not equal
					</div>
				</div>
			</div>
			<button
				type="button"
				class="btn btn-primary"
				:disabled="isBusy || !passwordValid || passwordNotEqual"
				@click="updateData()"
				v-if="$store.state.userData"
			>
				Update
			</button>
			<button
				type="button"
				class="btn btn-danger float-right"
				:disabled="isBusy || !passwordValid || passwordNotEqual"
				@click="confirmDeleteAccount()"
				v-if="$store.state.userData"
			>
				Delete account
			</button>
		</form>
	</div>
</template>

<style lang="scss"></style>
<script>
// @ is an alias to /src

export default {
	name: 'MyProfile',
	mounted() {
		this.init();
	},
	watch: {
		'$store.state.userData': function(userData) {
			this.regEmail = userData.email;
			this.regName = userData.name;
			this.language = userData.language;
		}
	},
	computed: {
		userData() {
			return this.$store.state.userData;
		}
	},
	data() {
		return {
			regEmail: this.$store.state.userData && this.$store.state.userData.email || '',
			regName: this.$store.state.userData && this.$store.state.userData.name || '',
			password: '',
			password2: '',
			language: this.$store.state.userData && this.$store.state.userData.language ||'pl',
			langs: [
				{ value: 'pl', text: 'Polski' },
				{ value: 'en', text: 'English' }
			],
			passwordNotEqual: false,
			passwordValid: true,
			error: '',
			isBusy: false
		};
	},
	methods: {
		init() {

		},
		validatePassword() {
			if (!this.password) {
				this.passwordValid = true;
			}
			else {
				this.passwordValid = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(
					this.password
				);
			}
			this.checkPasswordsEqual();
		},
		checkPasswordsEqual() {
			this.passwordNotEqual = !(this.password == this.password2);
		},

		async updateData() {
			this.isBusy = true;
			this.error = false;
			try {
				let options = {
					method: 'PATCH',
					cache: 'no-cache',
					redirect: 'error',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						name: this.regName,
						password: btoa(this.password),
						password2: btoa(this.password2),
						language: this.language
					})
				};
				let res = await fetch(`/api/v1/users/`+this.$store.state.userData.id, options);
				let data = await res.json();
				if (data && data.status == 'ok') {
					// updated
					this.$store.state.userData = data.userData;
				} else {
					this.error = data.message || 'Unexpected error';
				}
			} catch (e) {
				this.error = 'Unexpected error';
			}
			this.isBusy = false;
		},

		async confirmDeleteAccount() {
			this.isBusy = false;
			let result = await this.$bvModal.msgBoxConfirm(
				`Are you sure? All your data and levels will be deleted?`
			);
			try {
				if (result) {
					this.error = '';
					let options = {
						method: 'DELETE',
						cache: 'no-cache',
						redirect: 'error'
					};
					let res = await fetch('/api/v1/users/' + this.$store.state.userData.id, options);
					let data = await res.json();
					if (data && data.status == 'ok') {
						this.$store.state.userData = undefined;
						this.$router.push('/');
					} else {
						this.error = (data && data.message) || 'Unexpected error';
					}
				}
			} catch (e) {
				this.error = 'Unexpected error, '+e;
			}
			this.isBusy = false;
		},
	}
};
</script>
